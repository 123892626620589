import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from "./components/admin/Admin";
import Login from "./components/login/Login";

function App() {
      return (
            <Router>
                  <Routes>
                        <Route path='/' element={<Login />}/>
                        <Route path='/admin' element={<Admin />}/>
                  </Routes>
            </Router>
      )
}

export default App;

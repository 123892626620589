import "./login.css";
import adminPImg from "../../assets/adminPImg.png";
import logo from "../../assets/logo.png";
import lineT from "../../assets/lineT.png";
import lineB from "../../assets/lineB.png";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [validationPassword, setValidationPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push("Пароль должен быть длиной не менее 8 символов.");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Пароль должен содержать хотя бы одну заглавную букву.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Пароль должен содержать хотя бы одну строчную букву.");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Пароль должен содержать хотя бы одну цифру.");
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errors.push(
        "Пароль должен содержать хотя бы один специальный символ (!@#$%^&*)."
      );
    }

    return errors;
  };

  const formData = { password: password, username: username };

  const hundleSubmit = async (e) => {
    e.preventDefault();

    const errors = validatePassword(password);

    if (errors.length > 0) {
      setValidationPassword(errors.join(" "));
    } else {
      try {
        const response = await axios.post(
          "https://api.visitca.uz/v1/auth/organization_user_login",
          formData
        );
        localStorage.setItem("access_token", response.data.access_token);
        navigate("/admin");
        console.log(response);
      } catch (error) {
        setError("имя пользователя или пароль неверны");
        setValidationPassword("");
      }
    }
  };

  function hundleShowPassword() {
    setShowPassword(showPassword => !showPassword)
  }

  return (
    <div className="login_panel">
      <div className="login_panel_img">
        <img
          src={adminPImg}
          alt="login panel img"
          className="login_panel_image"
        />

        <img src={lineT} alt="line top" className="line_top" />
        <img src={lineB} alt="line bottom" className="line_bottom" />
      </div>

      <div className="login_panel_info">
        <div className="form-logo-wrapper">
          <img src={logo} alt="logo" className="logo_visitka" />

          <h2>Вход</h2>

          <form className="admin_form" onSubmit={hundleSubmit}>
            <div>
              <label>Логин</label>
              <input
                type="text"
                placeholder="Введите логин"
                required
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </div>

            <div>
              <label>Пароль</label>
              <span className="password-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Введите пароль"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span className="eye" onClick={hundleShowPassword}>
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </span>
              </span>
            </div>

            <button type="submit">Войти</button>

            {validationPassword && (
              <span style={{ color: "red" }}>{validationPassword}</span>
            )}
            {error && <span style={{ color: "red" }}>{error}</span>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
